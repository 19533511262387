import { template as template_eb351edd384c42cca70566b831512d68 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eb351edd384c42cca70566b831512d68(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
