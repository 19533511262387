import { template as template_0266d47124e34876a84e55adde16fd0b } from "@ember/template-compiler";
const FKControlMenuContainer = template_0266d47124e34876a84e55adde16fd0b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
